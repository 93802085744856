<template>
  <auth>
    <template slotscope="defaultSlotScope">
      <a-row type="flex" justify="space-around" align="middle" style="height :100vh ;" class="ant-row-flex">
        <a-col :lg="14" :md="16" :sm="18" :xl="10" :xs="20" :xxl="8">
          <a-row style="background-color: rgba(255, 255, 255, 0.75);" type="flex" justify="space-around" align="middle"
            class="ant-row-flex">
            <a-col :md="8" :sm="18" :xs="18">
              <img :src="orgLogoUrl" class="center" v-if="orgLogoUrl" />
            </a-col>
            <a-col :md="16" :sm="18" :xs="18">
              <a-alert v-if="errors" type="error" message="Email, Numéro de téléphone ou mot de passe erroné"
                style="margin-bottom: 30px;" banner />
              <a-form @submit="handleSubmit" :form="form" layout="vertical" style="padding: 5%;">
                <h1 style="text-align : center">Connexion</h1>
                <div class="splitter">
                  <a-form-item v-bind="formItemLayout">
                    <a-input placeholder="Email ou Numéro de téléphone" autocomplete="email" v-decorator="[
                      'contact',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Email ou numéro de téléphone est obligatoire!',
                          },
                          {
                            validator: (rule, value, callback) => {
                              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                              const phoneRegex = /^0\d?[-.\s]?\d{2}([-.\s]?\d{2}){3}$/; // Adjust regex to fit expected phone formats

                              if (!emailRegex.test(value) && !phoneRegex.test(value)) {
                                callback('Veuillez entrer un email ou un numéro valide!');
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                      },
                    ]">
                      <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
                    </a-input>
                  </a-form-item>

                  <a-form-item v-bind="formItemLayout">
                    <a-input-password placeholder="Mot de passe" autocomplete="current-password" v-decorator="[
                      'password',
                      {
                        rules: [
                          {
                            required: true,
                            message: 'Mot de passe est obligatoire!',
                          },
                        ],
                      },
                    ]">
                      <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
                    </a-input-password>
                  </a-form-item>

                  <a-form-item v-bind="tailFormItemLayout" class="margin">
                    <a-spin :spinning="spinning">
                      <a-button type="primary" class="regiter-form-button" htmlType="submit" id="login">{{ spinning ?
                        "Connexion..." : "Connexion" }}</a-button>
                    </a-spin>
                  </a-form-item>

                  <a-form-item v-bind="tailFormItemLayout" class="margin">
                    <a-button @click="goToRegisterPage" class="regiter-form-button" type="primary">
                      Demande d'inscription
                    </a-button>
                  </a-form-item>

                  <router-link to="/forgot_password" class="margin"
                    style="text-align : center ; color :#03A9F4; float : right ;">Mot de passe oublié ?</router-link>
                </div>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </template>
  </auth>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Auth from "@/components/common/AuthParent";

const REDIRECTION_URL = "/adherent/";
const PROFILE_URL = "/adherent/profil";
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 24,
      offset: 0,
    },
  },
};
const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};
export default {
  name: "AdherentLoginPage",
  components: {
    Auth,
  },
  created() {
    this.form = this.$form.createForm(this);
    this.resetAll();
    this.$store.cache.dispatch("fetchOrganisationLogoAndLoginBackgroundImage")
      .then(() => this.spinning = false)
  },
  mounted() {
    this.logout();
  },
  data() {
    return {
      form: null,
      errors: false,
      spinning: false,
      formItemLayout,
      tailFormItemLayout
    };
  },
  computed: {
    redirectUrl() {
      const fallback_url = (this.adherent && this.isUserNonSoumis(this.adherent.etat)) 
        ? PROFILE_URL : REDIRECTION_URL;

      return this.$route.query.returnUrl || fallback_url;
    },
    ...mapGetters({ 
      orgLogoUrl: "getOrganisationLogo",
      adherent: "getAdherent",
    }),
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.spinning = true;
          let user = {
            contact: values.contact,
            password: values.password,
          };
          this.auth({ user, role: "adherent" })
            .then(() => this.$router.push(this.redirectUrl))
            .catch(() => this.displayErrors())
            .finally(() => this.spinning = false);

        }
      });
    },
    goToRegisterPage() {
      this.$router.push("/adherent/inscription")
    },
    displayErrors() {
      this.$message.error('Vérifier votre mot de passe, email et/ou Numéro de téléphone', 4)
    },
    ...mapActions({
      auth: "authenticate",
      getAdherent: "fetchAdherent"
    }),
    ...mapMutations({ logout: "clearAuthentication", resetAll: "reset" }),
  },
};
</script>

<style scoped>
.splitter {
  border-left: solid;
  border-left-style: solid;
  border-width: thin;
  border-left-color: black;
  padding-left: 10px;
}

.margin {
  margin-bottom: 10px;
}

.mt {
  margin-top: 1%;
}

.regiter-form-button {
  width: 100%;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.c-footer {
  background: #009688;
}
</style>
