var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth",
    [
      [
        _c(
          "a-row",
          {
            staticClass: "ant-row-flex",
            staticStyle: { height: "100vh" },
            attrs: { type: "flex", justify: "space-around", align: "middle" }
          },
          [
            _c(
              "a-col",
              { attrs: { lg: 14, md: 16, sm: 18, xl: 10, xs: 20, xxl: 8 } },
              [
                _c(
                  "a-row",
                  {
                    staticClass: "ant-row-flex",
                    staticStyle: {
                      "background-color": "rgba(255, 255, 255, 0.75)"
                    },
                    attrs: {
                      type: "flex",
                      justify: "space-around",
                      align: "middle"
                    }
                  },
                  [
                    _c("a-col", { attrs: { md: 8, sm: 18, xs: 18 } }, [
                      _vm.orgLogoUrl
                        ? _c("img", {
                            staticClass: "center",
                            attrs: { src: _vm.orgLogoUrl }
                          })
                        : _vm._e()
                    ]),
                    _c(
                      "a-col",
                      { attrs: { md: 16, sm: 18, xs: 18 } },
                      [
                        _vm.errors
                          ? _c("a-alert", {
                              staticStyle: { "margin-bottom": "30px" },
                              attrs: {
                                type: "error",
                                message:
                                  "Email, Numéro de téléphone ou mot de passe erroné",
                                banner: ""
                              }
                            })
                          : _vm._e(),
                        _c(
                          "a-form",
                          {
                            staticStyle: { padding: "5%" },
                            attrs: { form: _vm.form, layout: "vertical" },
                            on: { submit: _vm.handleSubmit }
                          },
                          [
                            _c(
                              "h1",
                              { staticStyle: { "text-align": "center" } },
                              [_vm._v("Connexion")]
                            ),
                            _c(
                              "div",
                              { staticClass: "splitter" },
                              [
                                _c(
                                  "a-form-item",
                                  _vm._b(
                                    {},
                                    "a-form-item",
                                    _vm.formItemLayout,
                                    false
                                  ),
                                  [
                                    _c(
                                      "a-input",
                                      {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "contact",
                                              {
                                                rules: [
                                                  {
                                                    required: true,
                                                    message:
                                                      "Email ou numéro de téléphone est obligatoire!"
                                                  },
                                                  {
                                                    validator: function(
                                                      rule,
                                                      value,
                                                      callback
                                                    ) {
                                                      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                                                      var phoneRegex = /^0\d?[-.\s]?\d{2}([-.\s]?\d{2}){3}$/ // Adjust regex to fit expected phone formats

                                                      if (
                                                        !emailRegex.test(
                                                          value
                                                        ) &&
                                                        !phoneRegex.test(value)
                                                      ) {
                                                        callback(
                                                          "Veuillez entrer un email ou un numéro valide!"
                                                        )
                                                      } else {
                                                        callback()
                                                      }
                                                    }
                                                  }
                                                ]
                                              }
                                            ],
                                            expression:
                                              "[\n                    'contact',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Email ou numéro de téléphone est obligatoire!',\n                        },\n                        {\n                          validator: (rule, value, callback) => {\n                            const emailRegex = /^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$/;\n                            const phoneRegex = /^0\\d?[-.\\s]?\\d{2}([-.\\s]?\\d{2}){3}$/; // Adjust regex to fit expected phone formats\n\n                            if (!emailRegex.test(value) && !phoneRegex.test(value)) {\n                              callback('Veuillez entrer un email ou un numéro valide!');\n                            } else {\n                              callback();\n                            }\n                          },\n                        },\n                      ],\n                    },\n                  ]"
                                          }
                                        ],
                                        attrs: {
                                          placeholder:
                                            "Email ou Numéro de téléphone",
                                          autocomplete: "email"
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            color: "rgba(0,0,0,.25)"
                                          },
                                          attrs: {
                                            slot: "prefix",
                                            type: "user"
                                          },
                                          slot: "prefix"
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-item",
                                  _vm._b(
                                    {},
                                    "a-form-item",
                                    _vm.formItemLayout,
                                    false
                                  ),
                                  [
                                    _c(
                                      "a-input-password",
                                      {
                                        directives: [
                                          {
                                            name: "decorator",
                                            rawName: "v-decorator",
                                            value: [
                                              "password",
                                              {
                                                rules: [
                                                  {
                                                    required: true,
                                                    message:
                                                      "Mot de passe est obligatoire!"
                                                  }
                                                ]
                                              }
                                            ],
                                            expression:
                                              "[\n                    'password',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Mot de passe est obligatoire!',\n                        },\n                      ],\n                    },\n                  ]"
                                          }
                                        ],
                                        attrs: {
                                          placeholder: "Mot de passe",
                                          autocomplete: "current-password"
                                        }
                                      },
                                      [
                                        _c("a-icon", {
                                          staticStyle: {
                                            color: "rgba(0,0,0,.25)"
                                          },
                                          attrs: {
                                            slot: "prefix",
                                            type: "lock"
                                          },
                                          slot: "prefix"
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-item",
                                  _vm._b(
                                    { staticClass: "margin" },
                                    "a-form-item",
                                    _vm.tailFormItemLayout,
                                    false
                                  ),
                                  [
                                    _c(
                                      "a-spin",
                                      { attrs: { spinning: _vm.spinning } },
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            staticClass: "regiter-form-button",
                                            attrs: {
                                              type: "primary",
                                              htmlType: "submit",
                                              id: "login"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.spinning
                                                  ? "Connexion..."
                                                  : "Connexion"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-form-item",
                                  _vm._b(
                                    { staticClass: "margin" },
                                    "a-form-item",
                                    _vm.tailFormItemLayout,
                                    false
                                  ),
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        staticClass: "regiter-form-button",
                                        attrs: { type: "primary" },
                                        on: { click: _vm.goToRegisterPage }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Demande d'inscription\n                  "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "margin",
                                    staticStyle: {
                                      "text-align": "center",
                                      color: "#03A9F4",
                                      float: "right"
                                    },
                                    attrs: { to: "/forgot_password" }
                                  },
                                  [_vm._v("Mot de passe oublié ?")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }